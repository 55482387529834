
import './App.css';

import './style.css';
import Home from './component/Home/Home';




function App() {
  return (
    <>
    <Home />
  
    </>
  );
}

export default App;
