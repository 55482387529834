import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CTKLOGO from './assets/img/CTK-logo.png';



function Result() {

       const[message, setMessage]= useState("");
        
      const[query, setQuery]= useState("");
       
      const navigate = useNavigate();
    
      const [dataThesis, setdataThesis] = useState([]);

      useEffect(()=>{
        thesisData();
      },[]);

   const thesisData = async()=>{
     const reqData= await fetch("http://localhost:3002/api/thesis/search/");
     const resData= await reqData.json();
     console.log(resData);
     setdataThesis(await resData);

   }


  const  handleDelete=async(id)=>{
   
       let res= await fetch("http://localhost:3002/api/thesis/del/"+id,{
        method: "DELETE",
        headers:{ 'content-type': 'application-json'},
       });
       
       let resjson = await res.json();
       if(res.status===200){
         
        setMessage(resjson.success);
        setTimeout(()=>{
            navigate('/home/result');
            setMessage('');
        },1000)
                
       }else{
        setMessage("Theres an error in delete a file");
       }

  }

  const handleSubmit= async(e)=>{
  e.preventDefault();
  
  console.log(query);
//   return await axios.get()
//    .then()
//    .catch();


  }


   
    
    return (
        <>

            <header
                className="container try"
                style={{ width: '100%', backgroundRepeat: 'no-repeat' }}
            >
                <div className="text-center try">
                    <div className="">
                        <img
                            style={{ position: '' }}
                            src={CTKLOGO}
                            alt="logo"
                            width="150"
                            height="150"
                        />
                        <h1 style={{ color: 'maroon' }}>
                            <b>CHRIST THE KING COLLEGE OF SCIENCE & TECHNOLOGY</b>
                        </h1>
                    </div>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                 <div className="row-md-3">
                        <form onSubmit={handleSubmit}>
                       <div>
                    <input type="text" className="form-control" value={query} onChange={(e)=>setQuery(e.target.value)} placeholder="Search here..." />
                    <br></br>
                      <button type="submit" className="btn btn-primary">Search</button>
                    <Link to="/home/result" className="btn btn-primary mx-2">Reset</Link>
                    </div>
                     </form>
               </div>
                </div>
              
            </header>
            <br/>
            <br/>
             <p>{message}</p>
            <Link to="/home/resultguest" className="btn btn-primary mx-2">GUEST</Link>
   
            <Link to="/home/insert" className="btn btn-primary mx-2">ADD NEW DATA</Link>
         
          
            <br/>        
            <br/>
            <br></br>
            <table style={{textAlign: 'center'}}  className="table table-bordered">
                <thead>

                  
                 <tr>
                        <th>No.</th>
                        <th>TITLE</th>
                        <th>YEAR/LEVEL</th>
                        <th>BATCH</th>
                        <th>DATE ADDED</th>
                     
                    </tr>
               
                </thead>

                <tbody>


                  {/* {  
                    dataThesis.map( (dataThesis, index) => (    
                    <tr key={index}>
                         <td>{index+1}</td>
                         <td>{ dataThesis.title}</td>
                         <td>{ dataThesis.year}</td>
                         <td> { dataThesis.batch}</td>
                         <td> { dataThesis.createdAt}</td>
                         <td> <Link to={"/home/result/update/"+dataThesis.id} className="btn btn-success mx-1" style={{}}>EDIT</Link> <Link className="btn btn-danger mx-1" onClick={()=>handleDelete(dataThesis.id)}>DELETE</Link></td>
              

                    </tr>
                      ))
                    } */}

                    {
                          dataThesis.length > 0?(
                            dataThesis.map( (getData, index)=>(


                                <tr key={index}>
                                <td>{index+1}</td>
                                <td>{getData.title}</td>
                                <td>{getData.year}</td>
                                <td> {getData.batch}</td>
                                <td> {getData.createdAt}</td>
                                <td> <Link to={"/home/result/update/"+dataThesis.id} className="btn btn-success mx-1" style={{}}>EDIT</Link> <Link className="btn btn-danger mx-1" onClick={()=>handleDelete(dataThesis.id)}>DELETE</Link></td>
                     
       
                           </tr>



                             ))
                



                          ):(

                               <tr>
                                 <td>Records not Found</td>
                               </tr>

                          )

                    }


                </tbody>

                {/* asdasdsad */}


                
             

            </table>
        </>
    );
    
}

export default Result;