import React from 'react';

// function
function Register() {
    return (
        <div className="d-flex justify-content-center vh-100">
            <div className="align-self-center shadow p-4" id="register">
                <h1 className='text-center'>Register</h1>
                {/* <a style={{ textDecoration: 'none', color: 'black' }} href={'/Login'}>  BACK</a><br /> */}
                <form action="" method="post">
                    <label>Username</label><br />
                    <input className="w-100" type="text" name="username" id="username" /><br />
                    <label>Password</label><br />
                    <input className="w-100" type="password" name="password" id="password" /><br />
                    <label>Student ID</label><br />
                    <input className="w-100" type="number" name="id" id="id" /><br />
                    <label>Contact Number</label><br />
                    <input className="w-100" type="number" name="contnum" id="contnum" /><br />
                    <label>Email</label><br />
                    <input className="w-100" type="email" name="email" id="email" /><br />
                    <button className="btn w-100 my-3" type="submit" name="register">Register</button><br />
                    <a className="btn login-btn w-100" href={'/'}>Login </a >
                </form>
            </div>
        </div>
    );
}

export default Register;