// import

import React from 'react';


// function
function Delete() {
    return (
       <>

<p>Notifications</p>
       
       </>
    );
};

//export
export default Delete;


