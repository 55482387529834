import React from "react";


import ResultGuest from "../ThesisList/ResultGuest";
// import { Outlet } from "react-router-dom";

import './Home.css';


function Home() {
    return (
        <>
   
        <ResultGuest />
   
        </>
    )
}

export default Home;