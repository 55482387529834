import React, { useState, useEffect } from 'react';
import CTKLOGO from './assets/img/CTK-logo.png';
import { Link } from 'react-router-dom';








function ResultGuest() {
   
    const [userData, setdataThesis] = useState([]);

    useEffect( () => {
       const getDatathesis = async()=>{
         const reqData = await fetch("http://localhost:3002/api/thesis/search/");
         const resData = await reqData.json();
         setdataThesis(resData);
         console.log(resData)
       }
       getDatathesis();
    }, []);

    
    return (
        <>
         <br></br>
         <br></br>
        <body>
            
            {/* <a href={`/Home/Result`} style={{ textDecoration: 'none', color: '#fff', padding: '10px 20px', borderRadius: '30px', background: 'maroon', marginLeft: '30px', textAlign: 'left !important'  }}>ADMIN</a> */}
            <Link to={`/home/result`} className="btn btn-primary mx-3" style={{}}>ADMIN</Link>
        <br></br>
            <header
                className="container try"
                style={{ width: '100%', backgroundRepeat: 'no-repeat', marginBottom: '60px' }}
            >
                <div className="text-center try">
             
                    <div className="">
                        <img
                            style={{ position: '' }}
                            src={CTKLOGO}
                            alt="logo"
                            width="200"
                            height="200"
                        />
                        <h1 style={{ color: 'maroon' }}>
                            <b>CHRIST THE KING COLLEGE OF SCIENCE & TECHNOLOGY</b>
                        </h1>
                    </div>
                </div>
            
            </header>
         

            <br/>
            <table  className="table table-bordered">
                <thead>
                    <tr>
                        <th>NO.</th>
                        <th >THESIS TITLE</th>
                        <th >YEAR/LEVEL</th>
                        <th >BATCH</th>
                        <th >DATE ADDED</th>
                        <th >LINK</th>
                    </tr>
                </thead>

                <tbody>

                    { 
                      userData.map( (userData, index) => (    
                    <tr key={index}>
                            <td>{index+1}</td>
                       <td>{ userData.title}</td>
                         <td>{ userData.year}</td>
                         <td> { userData.batch}</td>
                         <td> { userData.createdAt}</td>
                         <td><Link to={`/home/result`} className="btn btn-primary mx-1" style={{}}>DOWNLOAD PDF</Link></td>

                    </tr>
                       ))
                    }

                </tbody>


            </table>

            </body>
        </>
    );
}

export default ResultGuest;