import React from 'react';
import ReactDOM from 'react-dom/client';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.css';
import './style.css'
import App from './App';
import Login from "./component/ThesisList/Login";
import ErrorView from "./component/Home/ErrorView";
import reportWebVitals from './reportWebVitals';
import Register from './component/ThesisList/Register';
import Result from './component/ThesisList/Result';

import Delete from './component/ThesisList/Delete';
import Update from './component/ThesisList/Update';
import ResultGuest from './component/ThesisList/ResultGuest';
import Insert from './component/ThesisList/Insert';



const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorView />,
  },

  {
    path: '/register',
    element: <Register />,
    errorElement: <ErrorView />,
  },
  {
    path: '/login',
    element: <Login />,
    errorElement: <ErrorView />,
  },
  {
    path: '/home/resultguest',
    element: <ResultGuest />,
    errorElement: <ErrorView />,
  },

  {
    path: '/home/result',
    element: <Result />,
    errorElement: <ErrorView />,
  },
  {
    path: '/home/delete',
    element: <Delete />,
    errorElement: <ErrorView />,
  },
  {
    path: '/home/insert',
    element: <Insert />,
    errorElement: <ErrorView />,
  },
  {
    path: '/home/result/update/:id',
    element: <Update />,
    errorElement: <ErrorView />,
  },

]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <App /> */}
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
