// import
import React from 'react';

// function
function Login() {
    return (
        <div className="d-flex justify-content-center vh-100">
            <div className="align-self-center p-4 shadow rounded" id="login">
                <h4 className="text-center">Sign in</h4>
                <form action="" method="post">
                    <label for="username">
                        Username<span style={{ color: 'red' }}>*</span>
                    </label><br />
                    <input type="text" id="username" name="username" className="w-100" placeholder="Enter Username" /><br />
                    <label for="password">
                        Password<span style={{ color: 'red' }}>*</span>
                    </label><br />
                    <input type="password" id="password" name="password" className="w-100" placeholder="Enter Password" /><br />
                    <span className="d-flex justify-content-around my-2">
                        <button type="submit" name="login" className="btn login-btn text-white w-100 mt-2 ms-1">Login</button>
                    </span>
                </form>
                {/* <a href="#" className="d-flex justify-content-center text-decoration-none">Forgot password?</a> */}
                {/* <div className="text-center">Don't have an account?</div> */}
                <a type="" href={'/Register'} className="btn reg-btn w-100 mt-2">Register</a>
            </div>
        </div>
    );
}

//export
export default Login;