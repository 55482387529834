// import

import React, { useState } from 'react';

import { useParams , useNavigate, Link} from 'react-router-dom';


function Update() {

  
       
     
     const {id}= useParams();
      
    

      const navigate = useNavigate();

      const [formValue, setFormValue]= useState({title:'', year:'', batch:''});

      const [message, setMessage]= useState('');
       
     

    const handleInput = (e) => {
        const {name, value} = e.target;
        setFormValue({...formValue, [name]: value});
   
     };

     
     const handleSubmit= async(e)=>{
        e.preventDefault();
        const allInput= {title: formValue.title, year: formValue.year, batch: formValue.batch};

      console.log(allInput);
       let res= await fetch("http://localhost:3002/api/thesis/update/"+id,{
          method: "PUT",
          headers:{'content-type': 'application/json'},
          body: JSON.stringify(allInput)

       });
       if(res.status===200){
         alert("success")
         setTimeout(()=>{
         navigate('/home/result');
         }, 2000);
      }
      else{
         alert("error")
      }
        

      }
     
   
    return (
       <>


<Link to="/home/result" className="btn btn-primary mx-2" style={{marginTop: "30px"}}>BACK</Link>

             <div className="container">

                 <div className="row">

                    <div className="col-md-12">

                         <h1>EDIT THESIS TITLE :{id}</h1>
                         
                         <form onSubmit={handleSubmit}>
                        
                             <div className="row">
                                  <div className='col-md-6'>

                                     <div className="mb-3">
                                          <label className="form-label">THESIS TITLE</label>
                                          <input type="text" name="title" className="form-control"onChange={handleInput}  value={formValue.title} />
                                    
                                     </div>

                                  </div>
                                   <div className='col-md-6'>

                                     <div className="mb-3">
                                          <label className="form-label">YEAR</label>
                                          <select name="year" className="form-control"onChange={handleInput}  value={formValue.year}  >
                                       
                                             <option value="">SELECT...</option>
                                             <option value="SHS">SHS</option>
                                             <option value="1ST YEAR">1ST YEAR</option>
                                             <option value="2ND YEAR">2ND YEAR</option>
                                             <option value="3RD YEAR">3RD YEAR</option>
                                             <option value="4TH YEAR">4TH YEAR</option>
                                          </select>
                                     </div>

                                  </div>

                                       <div className='col-md-6'>

                                     <div className="mb-3">
                                          <label className="form-label">BATCH</label>
                                          <select name="batch" className="form-control" onChange={handleInput} value={formValue.batch} >
                                       
                                             <option value="">SELECT...</option>
                                             <option value="ALPHA">ALPHA</option>
                                             <option value="BETA">BRAVO</option>
                                             <option value="CHARLIE">CHARLIE</option>
                                             <option value="DELTA">DELTA</option>
                                             <option value="FALCON">FALCON</option>
                                             <option value="GAMMA">GAMMA</option>
                                          </select>
                                     </div>

                                  </div>
                                  
                                   <div className="col-md-12">
                                       <div className="mb-3">
                                          <label className="form-label"></label>
                                          <button name="Update" className="btn btn-success btn-lg">Update</button>
                                       </div>

                                   </div>

                             </div>
                         </form>

                    </div>

                 </div>

             </div>




    
       
       
       </>
    );
};


export default Update;

