import { useRouteError } from "react-router-dom";

function ErrorView() {
    const error = useRouteError();
    // console.log(error);
    return (
        <div id="error-page">
            <h1>Oops!</h1>
            <p>Sorry, This project is not available or some error has occured!
                <p>
                    <i><b>{`${error.status}`}</b>{` || ${error.statusText} || ${error.error.message}`}</i>
                </p>
            </p>
        </div>
    )
};

export default ErrorView;